<template>
  <div>
    <!-- <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>权限管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色列表</el-breadcrumb-item>
    </el-breadcrumb> -->
    <el-row>
      <el-col>
        <el-button
          type="primary"
          @click="newIn"
          v-show="$store.state.btn_if.indexOf('system_auth_add') != -1"
          >添加角色</el-button
        >
      </el-col>
    </el-row>
    <el-card style="margin-top: 10px">
      <el-table :data="rolelist" border stripe :header-cell-style="rowClass">
        <!-- <el-table-column type="expand">
          <template slot-scope="scope">
            <el-row
              :gutter="20"
              v-for="(item1, i1) in scope.row.children"
              :key="item1.id"
              :class="['bdbottom', i1 == 0 ? 'bdtop' : '', 'vcenter']"
            >
              <el-col :span="5" :offset="0">
                <el-tag closable @close="removeid(scope.row, item1.id)">
                  {{ item1.label }}</el-tag
                >
                <i class="el-icon-caret-right"></i>
              </el-col>
              <el-col :span="19" :offset="0">
                <el-row
                  v-for="(item2, i2) in item1.children"
                  :key="item2.id"
                  :class="[i2 == 0 ? '' : 'bdtop', 'vcenter']"
                >
                  <el-col :span="6">
                    <el-tag
                      type="success"
                      closable
                      @close="removeid(scope.row, item2.id)"
                      >{{ item2.label }}</el-tag
                    >
                    <i class="el-icon-caret-right"></i>
                  </el-col>
                  <el-col :span="18">
                    <el-tag
                      v-for="item3 in item2.children"
                      :key="item3.id"
                      type="warning"
                      closable
                      @close="removeid(scope.row, item3.id)"
                      >{{ item3.label }}</el-tag
                    >
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </template>
        </el-table-column> -->
        <el-table-column label="编号" prop="id"></el-table-column>
        <!-- <el-table-column type="index"></el-table-column> -->
        <el-table-column label="角色名称" prop="title"></el-table-column>
        <el-table-column label="角色描述" prop="description"></el-table-column>
        <el-table-column label="操作" width="300px">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="showquanx(scope.row)"
              v-show="$store.state.btn_if.indexOf('system_auth_edit') != -1"
              class="skyblue"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="del(scope.row.id)"
              v-show="$store.state.btn_if.indexOf('system_auth_delete') != -1"

              class="red"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog
      title="修改角色"
      :visible.sync="setrightdialog"
      width="40%"
      @close="setpowerclose"
    >
      <el-form ref="formref" label-width="120px">
        <el-form-item label="角色名称">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input v-model="form.description"></el-input>
        </el-form-item>
        <el-form-item label="分配权限">
          <el-tree
            :data="rightslist"
            :props="treeprops"
            show-checkbox
            node-key="id"
            default-expand-all
            :default-checked-keys="form.rules.split(',')"
            ref="treeRef"
          ></el-tree>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="setrightdialog = false">取 消</el-button>
          <el-button type="primary" @click="allotrights">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="添加角色"
      :visible.sync="setrightdialogs"
      width="40%"
      @close="setpowerclose"
    >
      <el-form ref="formref" label-width="120px">
        <el-form-item label="角色名称">
          <el-input v-model="form_1.title"></el-input>
        </el-form-item>
        <el-form-item label="角色描述">
          <el-input v-model="form_1.description"></el-input>
        </el-form-item>
        <el-form-item label="分配权限">
          <el-tree
            :data="rightslist"
            :props="treeprops"
            show-checkbox
            node-key="id"
            default-expand-all
            :default-checked-keys="form_1.rules.split(',')"
            check-strictly
            ref="treeRefq"
          ></el-tree>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="setrightdialogs = false">取 消</el-button>
          <el-button type="primary" @click="allotrightsadd">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rolelist: [],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      // 这是对话框
      setrightdialog: false,
      setrightdialogs: false,
      rightslist: [],
      treeprops: {
        label: 'label',
        children: 'children'
      },
      form: {
        title: '',
        description: '',
        rules: ''
      },
      form_1: {
        title: '',
        description: '',
        rules: ''
      },
      defkey: [],
      roleid: ''
    }
  },
  created() {
    this.getrelelist()
  },
  methods: {
    newIn() {
      this.$router.push('/power')
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    getrelelist() {
      this.$http
        .get('/admin/AuthGroup/getList', {
          params: { page: this.currentPage, pageSize: this.pagesize }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            //   console.log(res);
            this.rolelist = res.data.list
            this.total = res.data.totalCount
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          this.$message.error('获取失败')
        })
    },
    getrole() {
      this.$http.get('/admin/AuthMenu/getList').then(({ data: res }) => {
        // 所有权限的数据
        this.rightslist = res.data
        console.log(this.rightslist)
      })
    },
    del(row) {
      // 根据id删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .post(`/admin/AuthGroup/del`, { id: row })
            .then(({ data: res }) => {
              //   row.children = res.data
              if (res.errorCode == 200) {
                this.$message.success(res.message)
                this.getrelelist()
              } else {
                this.$message.error(res.message)
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    handleSizeChange(e) {
      console.log(e)
    },
    handleCurrentChange(e) {
      console.log(e)
      this.currentPage = e
      this.getrelelist()
    },
    showquanx(role) {
      // 获取所有权限数据
      this.$router.push({
        path: '/power',
        query: {
          id: role.id
        }
      })
      this.roleid = role.id
      this.$http
        .get('/admin/AuthGroup/getInfo?id=' + this.roleid)
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.form = res.data
            console.log(this.form)
          } else {
            this.$message.error(res.message)
          }
        })
      this.$http.get('/admin/AuthMenu/getList').then(({ data: res }) => {
        // 所有权限的数据
        this.rightslist = res.data
        console.log(this.rightslist)
      })
      this.getlefkey(role, this.defkey)
      this.setrightdialog = true
    },
    getlefkey(node, arr) {
      // 通过递归
      if (!node.children) {
        return arr.push(node.id)
      }
      node.children.forEach(item => {
        this.getlefkey(item, arr)
      })
    },
    setpowerclose() {
      this.defkey = []
    },
    async allotrights() {
      const keys = [...this.$refs.treeRef.getCheckedKeys()]
      this.form.id = this.roleid
      var idstr = keys.join(',')
      this.form.rules = idstr
      console.log(idstr)
      const { data: res } = await this.$http.post(
        `/admin/AuthGroup/edit`,
        this.form
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.getrelelist()
        this.setrightdialog = false
      } else {
        this.$message.error(res.message)
      }
    },
    async allotrightsadd() {
      const keys = [...this.$refs.treeRefq.getCheckedKeys()]
      var idstr = keys.join(',')
      this.form_1.rules = idstr
      const { data: res } = await this.$http.post(
        `/admin/AuthGroup/add`,
        this.form_1
      )
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        location.reload()
        this.setrightdialogs = false
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-tag {
  margin: 7px;
}
.bdtop {
  border-top: 1px solid #eee;
}
.bdbottom {
  border-bottom: 1px solid #eee;
}
.vcenter {
  display: flex;
  align-items: center;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
